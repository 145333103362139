import { ButtonGroup } from "@chakra-ui/react";
import { domToReact } from "html-react-parser";
import options from "../../support/parse-settings";

const ButtonGroupWrapper = (props) => {
  const { children } = props;
  return <ButtonGroup {...props}>{domToReact(children, options)}</ButtonGroup>;
};

export default ButtonGroupWrapper;
